<div class="header">
    <h2 class="text-center">PRIVACY POLICY</h2>
</div>
<div class="container">
    <main>
        <section class="terms">
            <p>Last updated: June 5, 2024</p>
            <p>
                Welcome to the website for Kazi Flex <a href="https://kaziflex.com/">https://kaziflex.com/</a> (the
                "Site").
                Your privacy is important to us and this Privacy Policy applies to the information collected, stored,
                and used when you use the Site.
                Our “Services” mean the services provided by us.
            </p>
            <p>We recognize the importance of privacy as well as the importance of maintaining the confidentiality of
                personal information. This Privacy Policy applies to all products and services provided by us and sets
                out how we collect, use and disclose information in relation to users of the Site (however accessed).
                Please read this Privacy Policy carefully and make sure that you properly understand the provisions.
                Please do not use our Site if you disagree with any of the terms of this Privacy Policy.
            </p>
            <article>
                <h3>1. Important Information about us and the Site</h3>
                <p><strong>Kazi Flex</strong> is the controller and responsible for your personal data.</p>
                <p>If you have any questions about this Privacy Policy or our privacy practices, please contact us via
                    email <a href="mailto:privacy@kaziflex.com">privacy&#64;kaziflex.com</a>
                </p>
            </article>
            <article>
                <h3>2. Collection of Information</h3>
                <p>Your privacy is important to us and we have taken steps to ensure that we do not collect more
                    information from you than is necessary for us to provide you with our Services and to protect your
                    account.
                </p>
                <p>When you create an account on our Site, you may be required to provide us with certain information
                    including but not limited to:</p>
                <ul>
                    <li>Your name and email</li>
                    <li>In addition, after you have created your account you will also be required to provide us with
                        content related to your work history and photo for your profile picture.</li>
                </ul>
                <p>Together this is referred to as “User Generated Content”.</p>
            </article>
            <article>
                <h3>3. Use of Personal Data</h3>
                <p>We shall use your Personal Data in accordance with this Privacy Policy or as otherwise permitted by
                    law according to where you are located. </p>
                <p>Such permitted uses may include the following circumstances:</p>
                <ul>
                    <li>Verifying your identity</li>
                    <li>Responding to your queries, feedback, claims or disputes</li>
                    <li>Carrying our audience measurement / marketing and analytics to help us improve our Services.
                    </li>
                    <li>Implementing security measures to protect our Services, your account and data processed for our
                        Services against fraud and other security incidents </li>
                </ul>
            </article>
            <article>
                <h3>4. Rights Regarding Personal Data</h3>
                <p>Under the applicable laws, you may have the rights of access to Personal Data held by us and other
                    rights, including the right to correction of false or misleading data.</p>
                <p>If you have any questions regarding this Privacy Policy or if you wish to access or correct your
                    Personal Data as a user of the Site, you may send your request to
                    <a href="mailto:privacy@kaziflex.com">privacy&#64;kaziflex.com.
                    </a>
                </p>
                <p>You have the right at any time to prevent us from contacting you for marketing purposes. When we send
                    a promotional communication to a user, the user can opt out of further promotional communications by
                    following the unsubscribe instructions provided in each promotional e-mail. You can also indicate
                    that you do not wish to receive marketing communications from us in the settings of the Site. Please
                    note that notwithstanding the promotional preferences you indicate by either unsubscribing or opting
                    out in the settings of the Site, we may continue to send you administrative emails including, for
                    example, periodic updates to our Privacy Policy.
                </p>
            </article>
            <article>
                <h3>5. Security Measures</h3>
                <p>We have put in place appropriate security measures to prevent your Personal Data from being
                    accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we
                    limit access to your Personal Data to those employees, agents, contractors and other third parties
                    who have a business need to know. They will only process your Personal Data on our instructions and
                    they are subject to a duty of confidentiality. We also employ commercially reasonable security
                    methods to prevent unauthorized access to the Site, to maintain data accuracy and to ensure the
                    correct use of the information we hold
                </p>
                <p>We have put in place procedures to deal with any suspected personal data breach and will notify you
                    and any applicable regulator of a breach where we are legally required to do so.</p>
                <p>We recommend that you do not divulge your password to anyone. Our personnel will never ask you for
                    your password in an unsolicited phone call or in an unsolicited email. If you share a computer with
                    others, you should not choose to save your log-in information (e.g., user ID and password) on that
                    shared computer. Remember to sign out of your account and close your browser window when you have
                    finished your session.</p>
            </article>
            <article>
                <h3>6. Changes To This Privacy Policy</h3>
                <p>We may update this Privacy Policy from time to time in response to changing legal, technical or
                    business developments. When we update our Privacy Policy, we will take appropriate measures to
                    inform you, consistent with the significance of the changes we make. We will obtain your consent to
                    any material Privacy Policy changes if and where this is required by applicable data protection
                    laws. Any changes to this Privacy Policy will be communicated by us posting an amended Privacy
                    Policy on the Site. Once posted on the Site, the new Privacy Policy will be effective immediately.
                    You can see when this Privacy Notice was last updated by checking the “last updated” date displayed
                    at the top of this Privacy Notice.
                </p>
            </article>
            <article>
                <h3>7. Contact Us</h3>
                <p>You may write to us at <a href="mailto:info@kaziflex.com">info&#64;kaziflex.com</a> for any privacy
                    concerns and requests relating to these Terms, Disclaimers, the Privacy Policy, or our Site and
                    Services.</p>
            </article>
        </section>
    </main>
</div>