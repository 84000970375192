import { Component } from '@angular/core';
import { User } from '../../../shared/Interfaces/User';
import { ApiService } from '../../../core/services/api.service';
import { NotificationService } from '../../../core/services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { AnalyticsService } from '../../../core/services/analytics.service';

@Component({
  selector: 'app-service-freelancers',
  templateUrl: './service-freelancers.component.html',
  styleUrl: './service-freelancers.component.scss'
})
export class ServiceFreelancersComponent {
  /**
   * Stores the current page of the paginator
   */
  page = 1;
  /**
   * Stores the count of the paginator
   */
  count = 0;
  /**
   * Stores the total items displayed per page
   */
  items = 9;
  /**
   * Stores the category received from the URL
   */
  category: string;
  /**
   * Variable to store the freelancers
   */
  freelancers: User[] = [];
  /**
   * Displays spinner when waiting for data to be fetched from backend
   */
  fetchingFreelancers: boolean;

  /**
   * Adds dependencies into the component.
   * @param api - API service
   * @param notify - Notification service for displaying toast messages
   * @param router - Router for redirection
   */
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private notify: NotificationService,
    private router: Router,
    private auth: AuthenticationService,
    private analyticsService: AnalyticsService,
  ) { }

  /**
   * Fetches all freelancers when component is initialized
   */
  ngOnInit(): void {
    this.analyticsService.trackEvent('Service freelancers page Loaded', 'Service freelancers page Loaded into view', 'User accessed a service freelancers page');
    this.category = this.route.snapshot.url[1].path;
    if (this.category) {
      this.fetchFreelancers(this.category);
    }
  }

  /**
   * Removes hyphens between words in a given text and returns the original text.
   * If the text contains no hyphens, returns the original text unchanged.
   * @param {string} text - The text from which to remove hyphens.
   * @returns {string} - The modified text with hyphens removed between words.
   */
  removeHyphenBetweenWords(text: string): string {
    // Check if the text contains any hyphens
    if (text.includes('-')) {
      // If it does, split and join using space as separator
      return text.split('-').join(' ');
    } else {
      // If not, return the original text
      return text;
    }
  }


  /**
   * Fetches freelancers based on a specified category, removing hyphens if present in the category.
   * @param {string} category - The category of freelancers to fetch.
   * @returns {void} - This function does not return a value directly, but updates component state upon completion.
   */
  fetchFreelancers(category: string): void {
    this.category = this.removeHyphenBetweenWords(category);
    this.fetchingFreelancers = true;
    this.api.get('/users/get-service-freelancers/' + this.category).subscribe(
      res => {
        if (res.status_code === 200) {
          this.freelancers = res.detail;
        } else {
          this.notify.showError('An error occured.');
        }
        this.fetchingFreelancers = false;
      }
    );
  }

  /**
   * Detects if the data in the table has changed
   * @param page - The page that has been selected
   */
  onTableDataChange(page: number): void {
    this.page = page;
    this.fetchFreelancers(this.route.snapshot.url[1].path);
  }

  sendMessage(freelancer: User) {
    this.auth._id = freelancer._id;
    this.auth.firstname = freelancer.firstname;
    this.auth.lastname = freelancer.lastname;
    this.auth.redirectUrl = 'messages';
    this.router.navigate(['login'])
  }
}
