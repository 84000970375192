import { Component } from '@angular/core';
import { User } from '../../../shared/Interfaces/User';
import { ApiService } from '../../../core/services/api.service';
import { NotificationService } from '../../../core/services/notification.service';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsService } from '../../../core/services/analytics.service';

@Component({
  selector: 'app-freelancer-profile',
  templateUrl: './freelancer-profile.component.html',
  styleUrl: './freelancer-profile.component.scss'
})
export class FreelancerProfileComponent {
  /**
   * Stores the applicant's details
   */
  user: User;
  /**
   * Used to display spinner when waiting for the user to be fetched from the backend
   */
  userLoading: boolean;
  /**
   * Used to display spinner when uploading resume
   */
  downloadingResume: boolean;
  /**
   * Stores the applicant ID received from the history state
   */
  ID: string;

  /**
   * Adds dependencies into the component.
   * @param api - API service
   * @param notify - Notification service for displaying toast messages
   * @param authService - Authentication service
   */
  constructor(
    private api: ApiService,
    private notify: NotificationService,
    private route: ActivatedRoute,
    private analyticsService: AnalyticsService,
  ) { }

  /**
   * Fetches the applicant's details
   */
  ngOnInit(): void {
    this.ID = this.route.snapshot.url[1].path;
    if (this.ID) {
      this.fetchUser();
    }
    this.analyticsService.trackEvent('Freelancer Profile Page Loaded', 'Freelancer Profile Page Loaded into view', 'User accessed a freelancer profile page');
  }

  /**
   * Fetches the Freelancer's profile
   */
  fetchUser(): void {
    this.userLoading = true;
    this.api.get('/users/' + this.ID).subscribe(
      res => {
        if (res.status_code === 200) {
          this.user = res.detail;
        } else {
          this.notify.showError(res.detail);
        }
        this.userLoading = false;
      }
    );
  }

  downloadResume(resume: string): void {
    this.downloadingResume = true;
    this.api.getFile('/users/download-resume/' + this.user._id).subscribe(
      blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = resume;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(objectUrl);
        this.downloadingResume = false;
      }
    )
  }

}
