<div class="container">
    <div class="intro">
        <h2>Got Questions? We're Here to Help</h2>
        <p class="mb-5">
            Have questions about how Kaziflex works or need assistance with your account? 
            Our dedicated support team is available 24/7 to assist you. 
            Contact us anytime for prompt and reliable support: 
            <a href="mailto:info@kaziflex.com">info&#64;kaziflex.com</a>
        </p>
    </div>
    <div class="d-flex justify-content-center" *ngIf="isLoading">
        <app-loading></app-loading>
    </div>
    <div class="faqs" *ngIf="!isLoading">
        <h2>Frequently Asked Questions:</h2>
        <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" *ngFor="let faq of faqs">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{faq.question}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div [innerHtml]=faq.answer></div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>