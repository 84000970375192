<div class="header">
    <h1 class="text-center">TERMS & CONDITIONS</h1>
</div>
<div class="container">
    <main>
        <section class="terms">
            <p>Last updated: June 5, 2024</p>
            <p>
                PLEASE READ THESE TERMS CAREFULLY, AS THEY APPLY TO YOUR USE OF <a
                    href="https://kaziflex.com/">https://kaziflex.com/</a> (the "Site"). THESE TERMS OF USE (THESE
                “TERMS”) SET FORTH THE LEGALLY BINDING TERMS AND CONDITIONS THAT GOVERN YOUR USE OF THE SITE. BY
                ACCESSING OR USING THE SITE, YOU ARE ACCEPTING THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU
                REPRESENT), AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO
                THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT). YOU MAY NOT ACCESS OR USE THE SITE
                OR ACCEPT THE TERMS IF YOU ARE NOT AT LEAST 18 YEARS OLD. IF YOU DO NOT AGREE WITH ALL OF THE PROVISIONS
                OF THESE TERMS, DO NOT ACCESS AND/OR USE THE SITE.
            </p>
            <p>The website located at www.kaziflex.com (the “Site”) is a copyrighted work belonging to Kazi Flex
                (“Company”, “us”, “our”, and “we”). Certain features of the Site may be subject to additional
                guidelines, terms, or rules, which will be posted on the Site in connection with such features. All such
                additional terms, guidelines, and rules are incorporated by reference into these Terms.
            </p>
            <article>
                <h3>Definitions</h3>
                <p>Capitalised terms used in these Terms shall have the following meanings:</p>
                <p><strong>"Services":</strong> Means the services provided by us which includes the use of the Site</p>
            </article>
            <article>
                <h3>1. Access to the Site</h3>
                <p><strong>License:</strong> Subject to these Terms, Company grants you a non-transferable,
                    non-exclusive, revocable, limited license to use and access the Site solely for your own personal,
                    noncommercial use.</p>
                <p><strong>Certain Restrictions:</strong> The rights granted to you in these Terms are subject to the
                    following restrictions: (a) you shall not license, sell, rent, lease, transfer, assign, distribute,
                    host, or otherwise commercially exploit the Site, whether in whole or in part, or any content
                    displayed on the Site; (b) you shall not modify, make derivative works of, disassemble, reverse
                    compile or reverse engineer any part of the Site; (c) you shall not access the Site in order to
                    build a similar or competitive website, product, or service; and (d) except as expressly stated
                    herein, no part of the Site may be copied, reproduced, distributed, republished, downloaded,
                    displayed, posted or transmitted in any form or by any means. Unless otherwise indicated, any future
                    release, update, or other addition to functionality of the Site shall be subject to these Terms. All
                    copyright and other proprietary notices on the Site (or on any content displayed on the Site) must
                    be retained on all copies thereof.
                </p>
                <p><strong>Modification:</strong> Company reserves the right, at any time, to modify, suspend, or
                    discontinue the Site (in whole or in part) with or without notice to you. You agree that Company
                    will not be liable to you or to any third party for any modification, suspension, or discontinuation
                    of the Site or any part thereof.
                </p>
                <p><strong>Ownership:</strong> Excluding any User Content that you may provide (defined below), you
                    acknowledge that all the intellectual property rights, including copyrights, patents, trade marks,
                    and trade secrets, in the Site and its content are owned by Company or Company’s suppliers. Neither
                    these Terms (nor your access to the Site) transfers to you or any third party any rights, title or
                    interest in or to such intellectual property rights, except for the limited access rights expressly
                    set forth in Section 2.1. Company and its suppliers reserve all rights not granted in these Terms.
                    There are no implied licenses granted under these Terms.
                </p>
            </article>
            <article>
                <h3>2. User Content</h3>
                <p><strong>User Content:</strong> "User Content” means any and all information and content that a user
                    submits to, or uses with, the Site (e.g., content in the user’s profile or postings). You are solely
                    responsible for your User Content. You assume all risks associated with use of your User Content,
                    including any reliance on its accuracy, completeness or usefulness by others, or any disclosure of
                    your User Content that personally identifies you or any third party. You hereby represent and
                    warrant that your User Content does not violate our Acceptable Use Policy (defined in Section 3.3).
                    You may not represent or imply to others that your User Content is in any way provided, sponsored or
                    endorsed by Company. Since you alone are responsible for your User Content, you may expose yourself
                    to liability if, for example, your User Content violates the Acceptable Use Policy. Company is not
                    obligated to backup any User Content, and your User Content may be deleted at any time without prior
                    notice. You are solely responsible for creating and maintaining your own backup copies of your User
                    Content if you desire.</p>
                <p><strong>Acceptable Use Policy:</strong> The following terms constitute our <strong>“Acceptable Use
                        Policy”</strong>:
                </p>
                <ul>
                    <li> You agree not to use the Site to collect, upload, transmit,
                        display, or distribute any User Content (i) that violates any third-party right, including any
                        copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or
                        any
                        other intellectual property or proprietary right, (ii) that is unlawful, harassing, abusive,
                        tortious, threatening, harmful, invasive of another’s privacy, vulgar, defamatory, false,
                        intentionally misleading, trade libelous, pornographic, obscene, patently offensive, promotes
                        racism, bigotry, hatred, or physical harm of any kind against any group or individual or is
                        otherwise objectionable, (iii) that is harmful to minors in any way, or (iv) that is in
                        violation of
                        any law, regulation, or obligations or restrictions imposed by any third party.
                    </li>
                    <li>
                        In addition, you agree not to: (i) upload, transmit, or distribute to or through the Site any
                        computer viruses, worms, or any software intended to damage or alter a computer system or data;
                        (ii) send through the Site unsolicited or unauthorized advertising, promotional materials, junk
                        mail, spam, chain letters, pyramid schemes, or any other form of duplicative or unsolicited
                        messages, whether commercial or otherwise; (iii) use the Site to harvest, collect, gather or
                        assemble information or data regarding other users, including e-mail addresses, without their
                        consent; (iv) interfere with, disrupt, or create an undue burden on servers or networks
                        connected to the Site, or violate the regulations, policies or procedures of such networks; (v)
                        attempt to gain unauthorized access to the Site (or to other computer systems or networks
                        connected to or used together with the Site), whether through password mining or any other
                        means; (vi) harass or interfere with any other user’s use and enjoyment of the Site; or (vi) use
                        software or automated agents or scripts to produce multiple accounts on the Site, or to generate
                        automated searches, requests, or queries to (or to strip, scrape, or mine data from) the Site
                        (provided, however, that we conditionally grant to the operators of public search engines
                        revocable permission to use spiders to copy materials from the Site for the sole purpose of and
                        solely to the extent necessary for creating publicly available searchable indices of the
                        materials, but not caches or archives of such materials, subject to the parameters set forth in
                        our robots.txt file).
                    </li>
                </ul>
                <p><strong>Enforcement:</strong> We reserve the right (but have no obligation) to review, refuse and/or
                    remove any User Content in our
                    sole discretion, and to investigate and/or take appropriate action against you in our sole
                    discretion if you violate the Acceptable Use Policy or any other provision of these Terms or
                    otherwise create liability for us or any other person. Such action may include removing or modifying
                    your User Content, terminating your Account in accordance with Section 8, and/or reporting you to
                    law enforcement authorities
                </p>
            </article>
            <article>
                <h3>3. Code of Conduct</h3>
                <p>You agree to behave in such a way as to create a safe, supportive environment throughout your use of
                    the "site"
                </p>
                <p>You agree not to engage in any form of harassing, offensive, discriminatory or threatening speech or
                    behavior, including relating to race, gender, sexual orientation, religion or disability. If You
                    witness such behavior, please promptly contact our staff member so direct action can be taken.
                </p>
            </article>
            <article>
                <h3>4. Account Creation and Usage</h3>
                <p><strong>Account Creation:</strong> In order to use certain features of the Site, you must create
                    an account (“Account”) and provide certain information about
                    yourself as prompted by the account registration form.
                    You represent and warrant that:
                </p>
                <ul>
                    <li>All required registration information you submit is truthful and accurate</li>
                    <li>You will maintain the accuracy of such information. You may delete your Account at any time, for
                        any reason by following the instructions on the FAQs page.
                    </li>
                </ul>
                <p><strong>Account Responsibilities: </strong> You are responsible for maintaining the confidentiality
                    of
                    your Account login information and
                    are fully responsible for all activities that occur under your Account. You agree to immediately
                    notify Company of any
                    unauthorized use, or suspected unauthorized use of your Account or any other breach of security.
                    Company cannot and
                    will not be liable for any loss or damage arising from your failure to comply with the above
                    requirements.
                </p>
            </article>
            <article>
                <h3>5. Job Listings</h3>
                <p>Employers and recruiters are responsible for the accuracy and legitimacy of the job listings they
                    post on Kazi Flex.</p>
                <p> We reserve the right to remove any job listings that violate our policies or are deemed
                    inappropriate, without prior notice.</p>
            </article>
            <article>
                <h3>6. Information About You</h3>
                <p>Your contact details and other personal information will be handled in accordance with the
                    information handling practices described in the Privacy Policy. You consent to the collection, use
                    and disclosure of the information You enter when creating an account and any other additional
                    information in accordance with that Privacy Policy.
                </p>
            </article>
            <article>
                <h3>7. Indemnification</h3>
                <p>You hereby indemnify to the fullest extent Kazi Flex from and against any and all
                    liabilities, costs, demands, causes of action, damages and expenses arising in any way related to
                    your breach of any of the provisions of these Terms.</p>
            </article>
            <article>
                <h3>8. Term & Termination</h3>
                <p>Subject to this Section, these Terms will remain in full force and effect while you use the Site. We
                    may suspend or terminate your rights to use the Site (including your Account) at any time for any
                    reason at our sole discretion, including for any use of the Site in violation of these Terms. Upon
                    termination of your rights under these Terms, your Account and right to access and use the Site will
                    terminate immediately. You understand that any termination of your Account may involve deletion of
                    your User Content associated with your Account from our live databases. Company will not have any
                    liability whatsoever to you for any termination of your rights under these Terms, including for
                    termination of your Account or deletion of your User Content. Even after your rights under these
                    Terms are terminated, the following provisions of these Terms will remain in effect: Sections 2.2
                    through 2.6, Section 3 and Sections 4 through 10.
                </p>
            </article>
            <article>
                <h3>9. Severability</h3>
                <p>If any of these Terms are held invalid, illegal or unenforceable by any court or tribunal of
                    competent jurisdiction, it will be severed and the remaining terms will continue in full force and
                    effect as if these Terms had been made without the invalid, illegal or unenforceable terms.</p>
            </article>
            <article>
                <h3>10. Changes to these Terms</h3>
                <p>We may revise these from time to time to reflect changes in our Services. We encourage You to review
                    these Terms periodically to be informed of any latest changes. Where we consider any changes to
                    these Terms reasonably material, we will notify You prior to such changes becoming effective (to the
                    email you provided during the account creation process or any other means). You agree that by
                    creating an account, with or without notice from us, You consent to the
                    changes to these Terms.
                </p>
            </article>
            <article>
                <h3>11. Contact Us</h3>
                <p>You may write to us at <a href="mailto:info@kaziflex.com">info&#64;kaziflex.com</a> for any privacy
                    concerns and requests relating to these Terms, Disclaimers, the Privacy Policy, or our Site and
                    Services.</p>
            </article>
        </section>
    </main>
</div>