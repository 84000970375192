import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AboutComponent } from './components/about/about.component';
import { HomeComponent } from './components/home/home.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { FaqComponent } from './components/faq/faq.component';
import { ServiceFreelancersComponent } from './components/service-freelancers/service-freelancers.component';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { FreelancerProfileComponent } from './components/freelancer-profile/freelancer-profile.component';
import { BrowseJobsComponent } from './components/browse-jobs/browse-jobs.component';
import { PaymentTermsComponent } from './components/payment-terms/payment-terms.component';

const routes: Routes = [
  { path: '', component : HomeComponent },
  { path: 'home', component : HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'faqs', component: FaqComponent },
  { path: 'browse-jobs', component: BrowseJobsComponent },
  { path: 'terms-and-conditions', component: TermsConditionsComponent },
  { path: 'payment-terms', component: PaymentTermsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'freelancers/:category', component: ServiceFreelancersComponent },
  { path: 'freelancer/:id', component: FreelancerProfileComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
