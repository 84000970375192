import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../../../core/services/analytics.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss'
})
export class AboutComponent implements OnInit {
  constructor(
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    this.analyticsService.trackEvent('About Page Loaded', 'About Page Loaded into view', 'User accessed about page');
  }
}
