<div class="header">
    <h1 class="text-center">PAYMENT TERMS</h1>
</div>
<div class="container">
    <main>
        <section class="terms">
            <p>Last updated: October 2, 2024</p>
            <p>
                Welcome to Kazi Flex! We want to ensure that you have a clear understanding of how our payment system works
                so you can focus on what you do best—delivering quality work to your clients. This document will guide you
                through our payment schedules, explain key terms on your Earnings page, and help you track your income effectively.
            </p>
            <article>
                <h3>Payment Schedule</h3>
                <p><strong>Kazi Flex offers two payment windows each month:</strong></p>
                <ul>
                    <li>Mid-Month Payment: <strong>15th</strong> of every month</li>
                    <li>End-of-Month Payment: <strong>28th</strong> of every month</li>
                </ul>
                <p>These dates are the latest times you will receive payments for completed work, depending on the timing of your project activities.</p>
            </article>
            <article>
                <h3>How It Works</h3>
                <p><strong>Project Completion Before Payment Window:</strong> If you complete a project and it's marked as completed by
                    the client before the next payment window, you will receive your payment on the upcoming payment date
                </p>
                <p><strong>Project Completion After Payment Window:</strong> If your project is completed after a payment window has passed,
                    your payment will be processed in the following payment cycle.
                </p>
                <p><strong>Example:</strong> </p>
                <ul>
                    <li>If you complete a project on the 10th, you'll receive payment on the <strong>15th</strong>.</li>
                    <li>
                        If you complete a project on the 16th, you'll receive payment on the <strong>28th</strong>.
                    </li>
                </ul>
            </article>
            <article>
                <h3>Countdown to Next Payment Window</h3>
                <p>On your <strong>Earnings</strong> page, you'll see a countdown timer indicating the number of days remaining until the next 
                    payment window. This helps you know when to expect your next payout.
                </p>
            </article>
            <article>
                <h3>Understanding Your Earnings Page</h3>
                <p>Your Earnings page provides a comprehensive overview of your financial activities on Kazi Flex. Here's what each section means:</p>
                <p><strong>1. Pending Earnings</strong></p>
                <ul>
                    <li><strong>Definition:</strong> The amount of money you are set to earn from ongoing projects that are still in progress.</li>
                    <li><strong>Details:</strong> This includes all active jobs where work is being done but the client has not yet marked the project as completed.</li>
                    <li><strong>Purpose:</strong> Helps you anticipate future income from current projects.</li>
                </ul>
                <p><strong>2. Total Earnings</strong></p>
                <ul>
                    <li><strong>Definition:</strong> The cumulative amount you've earned from all projects that have been completed and approved by clients.</li>
                    <li><strong>Details:</strong> This reflects your success on the platform and can be useful for tracking your freelance income over time.</li>
                    <li><strong>Purpose:</strong> Gives you an overview of all the earnings you've made through completed work.</li>
                </ul>
                <p><strong>3. Pending Payments</strong></p>
                <ul>
                    <li><strong>Definition:</strong> The total amount from projects that have been completed and approved by clients but have not yet been paid out to you.</li>
                    <li><strong>Details:</strong> These payments are scheduled to be processed during the next payment window.</li>
                    <li><strong>Purpose:</strong> Helps you know how much money is on its way so you can plan accordingly.</li>
                </ul>
                <p><strong>4. Total Payments</strong></p>
                <ul>
                    <li><strong>Definition:</strong> The total amount of money you have received from all completed projects.</li>
                    <li><strong>Details:</strong> This is the actual income that has been transferred to your bank account or chosen payment method.</li>
                    <li><strong>Purpose:</strong> Allows you to track the funds you've successfully withdrawn from the platform.</li>
                </ul>
            </article>
            <article>
                <h3>Payment Process Flow</h3>
                <p><strong>1. Job Application:</strong> You apply for a job on the find work page.</p>
                <p><strong>2. Job Acceptance:</strong> The client selects you as their preffered freelancer for the job.</p>
                <p><strong>3. Work In Progress:</strong> The budget that the client has set for the job will be counted among the <strong>Pending Earning</strong>.</p>
                <p><strong>4. Work Submission:</strong> You submit work for the job and get feedback from the client.</p>
                <p><strong>5. Client Approval:</strong> 
                    When the client is satisfied with your work, they mark the job as <strong>completed.</strong>
                    The earnings move from <strong>Pending Earnings</strong> to <strong>Pending Payments.</strong>
                </p>
                <p><strong>6. Payment Window:</strong> 
                    On the next <strong>15th</strong> or <strong>28th</strong> of the month, depending on when the client marked the work as <strong>completed</strong>, your Pending Payments are processed.
                    The funds are transferred to you based on your preffered payment method, updating your Total Payments. <strong>Total Payments.</strong>
                </p>
            </article>
            <article>
                <h3>6. Key Points to Remember</h3>
                <p><strong>Timely Completion:</strong> Completing projects before the next payment window ensures you get paid as soon as possible.</p>
                <p><strong>Client Approval:</strong> Prompt communication with clients for project approval can speed up your payment process.</p>
                <p><strong>Track Your Earnings:</strong> Regularly check your Earnings page to stay updated on your financial status.</p>
                <p><strong>Payment Methods:</strong> Make sure your payment information is up-to-date to avoid any delays.</p>
            </article>
            <article>
                <h3>Frequently Asked Questions</h3>
                <p><strong>1. What happens if the payment date falls on a weekend or public holiday?</strong></p>
                <ul>
                    <li>Payments are processed on the next business day if a payment window falls on a weekend or public holiday.</li>
                </ul>
                <p><strong>2. Can I request an early payment?</strong></p>
                <ul>
                    <li> Payments are scheduled according to the fixed payment windows to maintain a consistent and reliable payment system for all freelancers.</li>
                </ul>
                <p><strong>3. What if my client delays marking the project as completed?</strong></p>
                <ul>
                    <li> It's important to communicate with your client to ensure timely approval. If delays occur, kindly remind them to mark the project as completed so your payment can be processed in the next cycle.</li>
                </ul>
            </article>
            <article>
                <h3>Support</h3>
                <p>If you have any questions or need assistance with your payments, please don't hesitate to contact our Customer Support 
                    Team at <a href="mailto:support@kaziflex.com">support&#64;kaziflex.com</a> We're here to help you succeed on Kazi Flex!
                </p>
                <p>By understanding how our payment schedules and earnings tracking work, you can better manage your freelance 
                    finances and focus on delivering exceptional work to your clients. Thank you for being a valued member of 
                    the Kazi Flex community!</p>
            </article>
        </section>
    </main>
</div>