<div class="container">
    <form [formGroup]="contactUsForm">
        <div class="row mb-3">
            <div class="form-group col-md-3">
                <label for="firstname">Firstname *</label>
                <input type="text" class="form-control" formControlName="firstname"
                    [ngClass]="{ 'is-invalid': submitted && f['firstname'].errors}">
                <div *ngIf="submitted && f['firstname'].errors" class="invalid-feedback">
                    <div *ngIf="f['firstname']['errors']required">Firstname is required</div>
                </div>
            </div>
            <div class="form-group col-md-3">
                <label for="lastname">Lastname *</label>
                <input type="text" class="form-control" formControlName="lastname"
                    [ngClass]="{ 'is-invalid': submitted && f['lastname'].errors}">
                <div *ngIf="submitted && f['lastname'].errors" class="invalid-feedback">
                    <div *ngIf="f['lastname']['errors']required">Lastname is required</div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="form-group col-md-6 mb-2">
                <label for="email">Email *</label>
                <input type="email" class="form-control" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f['email'].errors}">
                <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                  <div *ngIf="f['email']['errors']required">Email is required</div>
                  <div *ngIf="f['email']['errors']pattern">Input a valid email in lowercase</div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="form-group col-md-6 mb-4">
                <label for="message">Message * </label>
                <textarea class="form-control" formControlName="message" rows="4"
                    maxlength="400" [ngClass]="{ 'is-invalid': submitted && f['message'].errors }">
                </textarea>
                <div *ngIf="submitted && f['message'].errors" class="invalid-feedback">
                    <div *ngIf="f['message']['errors']required">Message is required</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 buttons d-flex">
                <button type="submit" class="btn btn-info flex-fill" (click)="submit()" [disabled]="isLoading">SUBMIT
                  <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>
            </div>
        </div>
    </form>
</div>