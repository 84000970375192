import { Component } from '@angular/core';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { faInbox } from '@fortawesome/free-solid-svg-icons';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import { faCrop } from '@fortawesome/free-solid-svg-icons';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { faKeyboard } from '@fortawesome/free-solid-svg-icons';
import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import { faBrain } from '@fortawesome/free-solid-svg-icons';
import { faSketch } from '@fortawesome/free-brands-svg-icons';
import { faTextWidth } from '@fortawesome/free-solid-svg-icons';
import { ApiService } from '../../../core/services/api.service';
import { NotificationService } from '../../../core/services/notification.service';
import { User } from '../../../shared/Interfaces/User';
import { AnalyticsService } from '../../../core/services/analytics.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
  /**
   * Displayed when the user selects employer option
   */
  fileImport = faFileImport;
  /**
   * Displayed when the user selects employer option
   */
  inbox = faInbox;
  /**
   * Displayed when the user selects employer option
   */
  check = faCircleCheck;
  /**
   * 
   */
  arrowRight = faArrowRight;
  /**
   * The services being offered
   */
  services = [
    {
      title: 'Digital Marketing',
      icon: faBullhorn
    },
    {
      title: 'Data Science',
      icon: faDatabase
    },
    {
      title: 'Software Development',
      icon: faCode
    },
    {
      title: 'Graphic Design',
      icon: faCrop
    },
    {
      title: 'UI UX',
      icon: faSketch
    },
    {
      title: 'Video Editing',
      icon: faVideo
    },
    {
      title: 'Transcription',
      icon: faTextWidth
    },
    {
      title: 'Copywriting',
      icon: faKeyboard
    },
    {
      title: 'Accounting',
      icon: faCalculator
    },
    {
      title: 'AI Services',
      icon: faBrain
    },
  ]
  /**
   * Variable to store the freelancers
   */
  freelancers: User[] = [];
  /**
   * Displays spinner when waiting for data to be fetched from backend
   */
  fetchingFreelancers: boolean;

  /**
   * Adds dependencies into the component.
   * @param api - API service
   * @param notify - Notification service for displaying toast messages
   * @param router - Router for redirection
   */
  constructor(
    private api: ApiService,
    private analyticsService: AnalyticsService,
    private notify: NotificationService,
  ) { }

  /**
   * Fetches all freelancers when component is initialized
   */
  ngOnInit(): void {
    this.fetchFreelancers();
    this.analyticsService.trackEvent('Home Page Loaded', 'Home Page Loaded into view', 'User accessed home page');
  }

  fetchFreelancers(): void {
    this.fetchingFreelancers = true;
    this.freelancers = [];
    this.api.get('/users/get-top-freelancers').subscribe(
      res => {
        if (res.status_code === 200) {
          this.freelancers = res.detail;
        } else {
          this.notify.showError(res.detail);
        }
        this.fetchingFreelancers = false;
      }
    );
  }

}
