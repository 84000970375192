import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../core/services/api.service';
import { NotificationService } from '../../../core/services/notification.service';
import { AnalyticsService } from '../../../core/services/analytics.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss'
})
export class ContactUsComponent implements OnInit{
  /**
   * Form for providing contact us details
   */
  contactUsForm: FormGroup;
  /**
   * Used for form validation and displaying errors
   */
  submitted = false;
  /**
   * Used to display spinner when the submit button is clicked
   */
  isLoading = false;

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private notify: NotificationService,
    private analyticsService: AnalyticsService,
  ){}
  /**
   * Fetches the logged in user when the component is initialized
   * Initializes contactUsForm with default values
   */
  ngOnInit(): void {
    this.contactUsForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', Validators.required],
      message: ['', Validators.required],
    });
    this.analyticsService.trackEvent('Contact us Page Loaded', 'Contact us page Loaded into view', 'User accessed contact us page');
  }

  /**
   * Getter for all the controls of the contactUsForm
   */
  get f(): FormGroup['controls'] {
    return this.contactUsForm.controls;
  }

  submit(): void {
    this.submitted = true;

    if (this.contactUsForm.invalid) {
      return;
    }

    this.isLoading = true;

    this.api.post('/users/contact-us', this.contactUsForm.value).subscribe(
      res => {
        if (res.status_code === 200) {
          this.notify.showSuccess(res.detail);
          this.isLoading = false;
          this.contactUsForm.reset();
        } else {
          this.notify.showError(res.detail);
          this.isLoading = false;
        }
        this.submitted = false;
      },
      err => {
        this.notify.showError(err);
        this.isLoading = false;
      }
    );
  }
}
