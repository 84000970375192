import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagesRoutingModule } from './pages-routing.module';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { FaqComponent } from './components/faq/faq.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SharedModule } from '../shared/shared.module';
import { ServiceFreelancersComponent } from './components/service-freelancers/service-freelancers.component';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { FreelancerProfileComponent } from './components/freelancer-profile/freelancer-profile.component';
import { BrowseJobsComponent } from './components/browse-jobs/browse-jobs.component';
import { AddHyphenPipe } from './pipes/add-hyphen.pipe';


@NgModule({
  declarations: [
    HomeComponent,
    AboutComponent,
    FaqComponent,
    PageNotFoundComponent,
    ServiceFreelancersComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    ContactUsComponent,
    FreelancerProfileComponent,
    BrowseJobsComponent,AddHyphenPipe
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    SharedModule
  ]
})
export class PagesModule { }
