<div class="container">
    <!-- <p class="text-center">
        At Kaziflex, we connect you with a diverse pool of talented freelancers ready to bring your projects to life.
        Whether you're looking for web developers, graphic designers, content writers, or marketing experts, 
        our platform is your gateway to finding the perfect match for your needs.
    </p> -->
    <!-- <div class="row">
        <div class="col-md-6">
            <img src="/assets/images/Canva5.svg">
        </div>
        <div class="col-md-6">
            <p>
                At Kaziflex, we connect you with a diverse pool of talented freelancers ready to bring your projects to life.
                Whether you're looking for web developers, graphic designers, content writers, or marketing experts, 
                our platform is your gateway to finding the perfect match for your needs.
            </p>
        </div>
    </div> -->
    <div class="why">
        <h2>Why Kaziflex</h2>
        <div class="statement">
            <h3>Diverse Talent:</h3>
            <p>Access a global network of freelancers with expertise in various industries and disciplines.</p>
        </div>
        <div class="statement">
            <h3>Flexibility:</h3>
            <p>Find freelancers for one-off projects or ongoing collaborations, without the commitments of traditional hiring.</p>
        </div>
        <div class="statement">
            <h3>Cost-Effective:</h3>
            <p>Save on overhead costs associated with hiring full-time employees and only pay for the work you need.</p>
        </div>
        <div class="statement">
            <h3>Quality Assurance:</h3>
            <p>Our platform ensures quality through freelancer ratings, reviews, and dispute resolution mechanisms.</p>
        </div>
        <div class="statement">
            <h3>Secure Transactions:</h3>
            <p>Enjoy peace of mind with our secure payment system and protection against fraudulent activities.</p>
        </div>
    </div>
</div>