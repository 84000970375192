import { Component } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { NotificationService } from '../../../core/services/notification.service';
import { Job } from '../../../shared/Interfaces/Job';
import { PagingConfig } from '../../../shared/Interfaces/PagingConfig';
import { AnalyticsService } from '../../../core/services/analytics.service';

@Component({
  selector: 'app-browse-jobs',
  templateUrl: './browse-jobs.component.html',
  styleUrl: './browse-jobs.component.scss'
})
export class BrowseJobsComponent {
  /**
   * Displays spinner when waiting for jobs to be fetched from backend
   */
  isLoading: boolean;
  /**
   * Variable to store the client's jobs
   */
  jobs: Job[] = [];
  /**
   * Used to check if there are any jobs
   */
  jobsAvailable: boolean;
  /**
   * Displays spinner when waiting for jobs to be fetched from backend
   */
  isLoading2: boolean;

  jobsPagingConfig: PagingConfig = {} as PagingConfig;

  /**
   * Adds dependencies into the component.
   * @param api - API service
   * @param notify - Notification service for displaying toast messages
   * @param router - Router for redirection
   */
  constructor(
    private api: ApiService,
    private analyticsService: AnalyticsService,
    private notify: NotificationService,
  ) { }

  /**
   * Fetches jobs when the component is initialized
   * Sets the default values of the forms
   */
  ngOnInit(): void {
    this.fetchJobs();
    this.jobsPagingConfig = {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: 0
    };
    this.analyticsService.trackEvent('Browse Jobs Page Loaded', 'Browse Jobs Page Loaded into view', 'User accessed Browse Jobs page');
  }

  fetchJobs() {
    this.isLoading2 = true;
    this.jobs = [];
    this.api.get('/jobs/browse').subscribe({
      next: res => {
        if (res.status_code === 200) {
          this.jobsPagingConfig.totalItems = res.detail.length;
          if (res.detail.length > 0) {
            this.jobsAvailable = true;
            this.jobs = res.detail;
          } 
        } else {
          this.notify.showError(res.detail);
        }
        this.isLoading2 = false;
      },
      error: (e) => {
        this.isLoading2 = false;
        console.error(e);
      }
    }); 
  }

  /**
   * Detects if the data in the transactions has changed
   * @param page - The page that has been selected
   */
  onJobsDataChange(event: any): void{
    this.jobsPagingConfig.currentPage = event;
    this.fetchJobs();
  }

}
