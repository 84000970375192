import { Component } from '@angular/core';
import { AnalyticsService } from '../../../core/services/analytics.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrl: './page-not-found.component.scss'
})
export class PageNotFoundComponent {
  constructor(
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    this.analyticsService.trackEvent('Page not found Loaded', 'Page not found Loaded into view', 'User accessed page not found');
  }
}
