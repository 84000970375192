import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { NotificationService } from '../../../core/services/notification.service';
import { FAQ } from '../../../shared/Interfaces/FAQ';
import { AnalyticsService } from '../../../core/services/analytics.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss'
})
export class FaqComponent implements OnInit{
  /**
   * State of Mat Expansion Panel
   */
  panelOpenState: boolean;
  /**
   * Stores list of FAQs fetched from the backend
   */
  faqs: FAQ[];
  /**
   * Displays spinner when waiting for data to be fetched from backend
   */
  isLoading = true;

  /**
   * Injects dependencies into the component
   */
  constructor(
    private api: ApiService,
    private notify: NotificationService,
    private analyticsService: AnalyticsService
  ) { }

  /**
   * Fetches all FAQs when component is initialized
   */
  ngOnInit(): void {
    this.analyticsService.trackEvent('FAQs Page Loaded', 'FAQs Page Loaded into view', 'User accessed FAQs page');
    this.api.get('/faqs/').subscribe({
      next: res => {
        if (res.status_code === 200) {
          this.faqs = res.detail;
          this.isLoading = false;
        } else {
          this.isLoading = false;
          this.notify.showError(res.detail);
        }
      },
      error: (e) => {
        this.isLoading = false;
        this.notify.showError(e);
      }
    });
  }
}
