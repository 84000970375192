<div class="jumbotron">
    <div class="container-fluid">
        <div class="container">
            <div class="left">
                <h2>Welcome to</h2>
                <h1>KAZIFLEX</h1>
                <!-- <p>
                    At Kaziflex, we connect you with a diverse pool of talented freelancers ready to bring your projects to life.
                   Whether you're looking for web developers, graphic designers, content writers, or marketing experts, 
                   our platform is your gateway to finding the perfect match for your needs.
                </p> -->
                <p>
                    Whether you are a business looking to outsource projects or a freelancer seeking opportunities to showcase your skills,
                    Kazi Flex is your ultimate destination. Sign up now to start your freelancing journey or post your project and 
                    experience the power of collaboration.
                </p>
                <div class="row">
                    <div class="col-md-6 mb-2">
                        <button type="submit" routerLink="/login" class="btn btn-info">POST A JOB</button>
                    </div>
                    <div class="col-md-6">
                        <button type="submit" routerLink="/login" class="btn btn-info">FIND WORK</button>
                    </div>
                </div>
            </div>
            <div class="right">
                <img src="/assets/images/Canva1.svg">
            </div>
        </div>
    </div>
</div>
<div class="container middle" *ngIf="!fetchingFreelancers">
    <h3 class="text-center" *ngIf="freelancers.length > 0">Top Freelancers</h3>
    <div class="freelancers" *ngIf="freelancers.length > 0">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 mb-4" *ngFor="let freelancer of freelancers">
            <div class="card" style="width: 100%;">
              <div class="card-body">
                <div class="avatar">
                  <div class="avatar-preview">
                    <div class="image-preview" [style.backgroundImage]="'url('+ freelancer.profile_image +')'"></div>
                  </div>
                </div>
                <h5 class="card-title">{{freelancer.firstname}} {{freelancer.lastname}}</h5>
                <p class="card-text text-center">{{freelancer.title}}</p>
                <p class="card-text text-center">
                  <span class="badge rounded-pill text-bg-light" *ngFor="let skill of freelancer.skills ">{{skill}}</span>
                </p>
                <a class="btn btn-info" [href]="'/freelancer/' + freelancer._id">VIEW PROFILE</a>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>
<div class="content">
  <div class="container">
      <h3>Popular Services</h3>
      <div class="row">
          <a class="card" style="width: 15rem;" *ngFor="let service of services" [href]="'/freelancers/' + (service.title | addHyphen)">
            <fa-icon [icon]="service.icon" size="3x"></fa-icon>
            <div class="card-body">
              <p class="card-text">{{service.title}}</p>
            </div>
          </a>
      </div>
  </div>
</div>