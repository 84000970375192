import { Component } from '@angular/core';
import { AnalyticsService } from '../../../core/services/analytics.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrl: './privacy-policy.component.scss'
})
export class PrivacyPolicyComponent {
  constructor(
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    this.analyticsService.trackEvent('Privacy policy page Loaded', 'Privacy policy page Loaded into view', 'User accessed privacy policy page');
  }
}
