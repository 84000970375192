import { Component } from '@angular/core';
import { AnalyticsService } from '../../../core/services/analytics.service';

@Component({
  selector: 'app-payment-terms',
  templateUrl: './payment-terms.component.html',
  styleUrl: './payment-terms.component.scss'
})
export class PaymentTermsComponent {
  constructor(
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    this.analyticsService.trackEvent('Payment terms page Loaded', 'Payment terms page Loaded into view', 'User accessed payment terms page');
  }
}
